import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Indi from "../Assets/Images/Individual.webp";
import Org from "../Assets/Images/org.webp";
import time from "../Assets/Images/time.webp";
import ear from "../Assets/Images/ear.svg";
import bilangage from "../Assets/Images/bilanguage.svg";
import artist from "../Assets/Images/artist.svg";
import collab from "../Assets/Images/collab.svg";
import decision from "../Assets/Images/decision.svg";
import potential from "../Assets/Images/potential.svg";
import productivity from "../Assets/Images/productivity.svg";
import security from "../Assets/Images/security.svg";
import travel from "../Assets/Images/travel.svg";
const variantsLeft = {
  hidden: { opacity: 0, x: -50 },
  visible: { opacity: 1, x: 0 },
};

const variantsRight = {
  hidden: { opacity: 0, x: 50 },
  visible: { opacity: 1, x: 0 },
};

const Services = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  return (
    <section className="services-section" id="services">
      <div className="container mx-auto">
        <h2 className=" text-center text-2xl  md:text-5xl font-black mt-12 mb-10">
          Services
        </h2>
        <div className="flex flex-wrap justify-around gap-10 " ref={ref}>
          <a href="/user">
            <motion.div
              className="max-h-96 border border-sky-500 rounded-3xl service-card "
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={variantsLeft}
              transition={{ duration: 0.5 }}
            >
              <div className="">
                <div className="">
                  <img src={Indi} alt=""></img>
                  <div className="my-3">
                    <div className="flex items-center">
                      <img src={time} className="w-8 h-8" alt=""></img>
                      <p className="m-3 font-normal  dark:text-gray-400">
                        Master Your Time, Effortlessly
                      </p>
                    </div>
                    <div className="flex items-center">
                      <img src={bilangage} className="w-8 h-8" alt=""></img>
                      <p className="m-3 font-normal  dark:text-gray-400">
                        Your Personal Polyglot
                      </p>
                    </div>
                    <div className="flex items-center">
                      <img src={travel} className="w-8 h-8" alt=""></img>
                      <p className="m-3 font-normal  dark:text-gray-400">
                        Travel Planning Like a Jetsetter
                      </p>
                    </div>
                    <div className="flex items-center">
                      <img src={artist} className="w-8 h-8" alt=""></img>
                      <p className="m-3 font-normal  dark:text-gray-400">
                        Unleash Your Inner Artist
                      </p>
                    </div>
                    <div className="flex items-center">
                      <img src={ear} className="w-8 h-8" alt=""></img>
                      <p className="m-3 font-normal  dark:text-gray-400">
                        Empathy, Encouragement, and A Friendly Ear
                      </p>
                    </div>
                  </div>
                  <p className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Know more
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 ml-2 -mr-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </p>
                </div>
              </div>
            </motion.div>
          </a>
          <a href="/organisation">
            <motion.div
              className="max-h-96 border border-sky-500 rounded-3xl service-card"
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={variantsRight}
              transition={{ duration: 0.5 }}
            >
              <div>
                <div className="">
                  <img src={Org} alt=""></img>

                  <div className="my-3">
                    <div className="flex items-center">
                      <img src={productivity} className="w-8 h-8" alt=""></img>
                      <p className="m-3 font-normal  dark:text-gray-400">
                        Intelligent Productivity
                      </p>
                    </div>
                    <div className="flex items-center">
                      <img src={collab} className="w-8 h-8" alt=""></img>
                      <p className="m-3 font-normal  dark:text-gray-400">
                        Seamless Collaboration
                      </p>
                    </div>
                    <div className="flex items-center">
                      <img src={decision} className="w-8 h-8" alt=""></img>
                      <p className="m-3 font-normal  dark:text-gray-400">
                        Informed Decision-Making
                      </p>
                    </div>
                    <div className="flex items-center">
                      <img src={potential} className="w-8 h-8" alt=""></img>
                      <p className="m-3 font-normal  dark:text-gray-400">
                        Empowering Human Potential
                      </p>
                    </div>
                    <div className="flex items-center">
                      <img src={security} className="w-8 h-8" alt=""></img>
                      <p className="m-3 font-normal  dark:text-gray-400">
                        Uncompromised Security
                      </p>
                    </div>
                  </div>
                  <p className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Coming Soon
                    <svg
                      aria-hidden="true"
                      className="w-4 h-4 ml-2 -mr-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </p>
                </div>
              </div>
            </motion.div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Services;
