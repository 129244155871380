import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import animationData from "../Assets/Lottie/79793-guy-talking-to-robot-ai-help.json";
const HowItWorks = () => {
  const [data, setdata] = useState([]);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch("https://chat1.ocpl.tech/get");
      const response = await data.json();
      setdata(response.message);
    };

    fetchData();
  }, []);

  return (
    <section className=" work-section" id="works">
      <div className="container mx-auto">
        <h1 className=" text-center text-2xl  md:text-5xl font-black mt-12 mb-10">
          How AESTRA Works
        </h1>
        <div className="p-4 sm:p-10 grid grid-cols-1 lg:grid-cols-2 gap-10 text-white">
          <div className="work-subtitle ">
            {data && (
              <>
                {data.map((res) => {
                  return (
                    <div key={res.id}>
                      <p className="text-xl sm:text-3xl mb-4">
                        <span>{res.id}. </span>
                        {res.title} :
                      </p>
                      <p className="sm:text-xl text-base mb-4">{res.feature}</p>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="flex justify-center items-center lottie-container">
            <Lottie options={defaultOptions} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
