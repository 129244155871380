import Swal from "sweetalert2";

const FailurePage = () => {
    Swal.fire({
        title: `Your Payment was Unsuccessful`,
        text: 'Please Try Again!',
        icon: 'error',
        // timer: 5000,
        // timerProgressBar: true,
    
        // 'Your Payment was Success',
        // 'T,
        willClose: () => {
            window.location.href="https://aestra.ocpl.tech";
          },
         })
    return(
<div>Your payment was unsuccess</div>
    )
}
export default FailurePage;