import React from "react";
import LOGO from "../Assets/Images/Whats.webp";
import AESTRA from "../Assets/Images/aestra.webp";
import { AiOutlineInstagram } from "react-icons/ai";
const Footer = () => {
  return (
    <footer className=" rounded-lg shadow bg-black-200 mx-2 mb-2 mt-16">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="flex items-center justify-around flex-wrap">
          <a
            href="/"
            className="flex items-center mb-4 sm:mb-0"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <img src={LOGO} className="h-24 mr-3" alt="AESTRA Logo" />
            <div className="flex flex-col gap-3">
              <img src={AESTRA} className="h-8 mr-3" alt="AESTRA" />
              <span className="text-sm font-medium text-secondary sm:mb-4 text-white">
                info@aestra.ocpl.tech
              </span>
            </div>
          </a>
          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-secondary sm:mb-4 text-white">
            <li className="mt-3">
              <a
                href="/privacy"
                className="mr-4 hover:underline md:mr-6"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Privacy Policy
              </a>
            </li>
            <li className="mt-3">
              <a
                href="/terms"
                className="hover:underline md:mr-6 mr-4"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Terms and Conditions(Fair Use)
              </a>
            </li>
            <li className="mt-3">
              <a
                href="/refund"
                className="hover:underline md:mr-6 mr-4"
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Refund and Cancellation Policy
              </a>
            </li>
            <li className="mt-3">
              <a
                href="https://www.instagram.com/aestra_ai/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <AiOutlineInstagram className="w-5 h-5" />
              </a>
            </li>
          </ul>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="flex justify-center text-sm text-center text-white footer-bottom">
          © 2023 &nbsp;
          <p>
            AESTRA&#8482; By&nbsp;
            <a
              href="https://ocpl.tech/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              OCPL Tech.
            </a>
          </p>
          All Rights Reserved.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
