import Phone from "../Assets/Images/white.webp";
import Information from "../Assets/Lottie/96057-tta-information-session.json";
import Language from "../Assets/Lottie/77488-translate-language.json";
import Instant from "../Assets/Lottie/99625-instant-process.json";
import Personal from "../Assets/Lottie/76107-customer-support.json";
import Security from "../Assets/Lottie/93112-security.json";
import Basic from "../Assets/Images/free.webp";
import Premium from "../Assets/Images/paid.webp";
export const slides = [
  {
    id: 1,
    title: "Smart Replies",
    description:
      " Our advanced AI technology generates contextually relevant and intelligently crafted responses in real-time, giving you an edge in every conversation.",
    image: Phone,
    lottie: Information,
  },
  {
    id: 2,
    title: "Linguist Support",
    description:
      "AESTRA understands and communicates in multiple languages, bridging the gap between diverse cultures and fostering seamless global interaction.",
    image: Phone,
    lottie: Language,
  },
  {
    id: 3,
    title: "Instant Data",
    description:
      "Access a wealth of knowledge at your fingertips, as AESTRA searches for accurate answers to your questions in seconds.    ",
    image: Phone,
    lottie: Instant,
  },
  {
    id: 4,
    title: "Personal Assistant",
    description:
      "Manage your daily tasks and reminders with ease as AESTRA keeps you organized and on track. ",
    image: Phone,
    lottie: Personal,
  },
  {
    id: 5,
    title: "Enhanced Security",
    description:
      "Your data and privacy are of utmost importance. AESTRA is built with advanced encryption to protect your sensitive information. ",
    image: Phone,
    lottie: Security,
  },
];

export const heroContent = {
  id: 1,
  title: "Your Personal AI Assistant",
  slider: [
    {
      id: 1,
      line: "Chat Your Way to an Organized Day!",
    },
    {
      id: 2,
      line: "Your Pocket-sized Powerhouse for Perfect Planning!",
    },
    {
      id: 3,
      line: "Life Managed at Your Fingertips!",
    },
    {
      id: 4,
      line: "Chat Your Way to an Organized Day!",
    },
    {
      id: 5,
      line: "Time Saved, Tasks Sorted, All in a Text!",
    },
    {
      id: 6,
      line: " Your Everyday Efficiency Expert, Just a Chat Away!",
    },
  ],
  description:
    "AESTRA is an intelligent, intuitive, and interactive AI chatbot that integrates seamlessly with WhatsApp, one of the world's most popular messaging platforms. With its advanced  natural language processing capabilities, it understands user   queries and responds accurately, providing real-time support and   personalized experiences.",
  buttonText: "Ignite Possibilities!",
};

export const userPrice = [
  {
    id: 1,
    title: "Basic",
    price: "Free",
    icon: Basic,
    time: "month",
    benefits: [
      {
        id: 1,
        benefit: "Limited task and calendar management service",
      },
      {
        id: 2,
        benefit:
          "Limited files and documents management service (3 files/month)",
      },
      {
        id: 3,
        benefit: "5 Voice call capabilities",
      },
      {
        id: 4,
        benefit: "Gamification",
      },
      {
        id: 5,
        benefit: "Language Translation",
      },
      {
        id: 6,
        benefit: "Natural Language Processing",
      },
      {
        id: 7,
        benefit: "Travel Planning Feature (2 inputs per day)",
      },
    ],
    buttonText: "Get Started",
  },
  {
    id: 2,
    title: "Premium",
    price: "199",
    icon: Premium,
    time: "month",
    benefits: [
      {
        id: 1,
        benefit: "5GB Storage for files and documents",
      },
      {
        id: 2,
        benefit: "20 voice call capabilities",
      },
      {
        id: 3,
        benefit: "Better Gamification Experience",
      },
      {
        id: 4,
        benefit: "Chat Relevance Refresh Timeout 150messages/24hrs ",
      },
      {
        id: 5,
        benefit: "Unlimited Tasks and calendar management service",
      },
      {
        id: 6,
        benefit: "Meeting Summarization",
      },
      {
        id: 7,
        benefit: "Collaboration tools Integration ",
      },
    ],
    buttonText: "Get Started",
  },
];
export const orgPrice = [
  {
    id: 1,
    title: "Free",
    price: "00",
    time: "month",
    benefits: [
      {
        id: 1,
        benefit: "lorem lorem lorem lorem",
      },
      {
        id: 2,
        benefit: "lorem lorem lorem lorem",
      },
      {
        id: 3,
        benefit: "lorem lorem lorem lorem",
      },
      {
        id: 4,
        benefit: "lorem lorem lorem lorem",
      },
    ],
    buttonText: "Get Started",
  },
  {
    id: 2,
    title: "Basic",
    price: "12000",
    time: "month",
    benefits: [
      {
        id: 1,
        benefit: "lorem lorem lorem lorem",
      },
      {
        id: 2,
        benefit: "lorem lorem lorem lorem",
      },
      {
        id: 3,
        benefit: "lorem lorem lorem lorem",
      },
      {
        id: 4,
        benefit: "lorem lorem lorem lorem",
      },
    ],
    buttonText: "Get Started",
  },
];
