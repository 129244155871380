import React, {
  useLayoutEffect,
  useRef,
  useMemo,
  useEffect,
  Suspense,
} from "react";

import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber";
import {
  TextureLoader,
  LinearFilter,
  RGBFormat,
  Vector2,
  Object3D,
} from "three";
import { Texture } from "three";
import { OrbitControls, Preload } from "@react-three/drei";

import { gsap } from "gsap";

import { vertex, fragment } from "./shader";
import img_0 from "../Assets/Images/animation.webp";
import CanvasLoader from "../Loader/Loader";

const size = 64;
const canvas = document.createElement("canvas");
canvas.width = size;
canvas.height = size;
 const texture = new Texture(canvas);

const Particles = () => {
  const instanceMeshRef = useRef();

  let { uSize, uDepth } = useMemo(() => {
    let uSize = { value: 0.0 };
    let uDepth = { value: -30.0 };

    return { uSize, uDepth };
  }, []);

  const text = useLoader(TextureLoader, img_0, (texture) => {
    texture.minFilter = LinearFilter;
    texture.magFilter = LinearFilter;
    texture.format = RGBFormat;
  });

  const width = text.image.width;
  const height = text.image.height;
  const dots = width * height;

  const { sIndices, sPositions } = useMemo(() => {
    const sIndices = new Uint16Array(dots);
    const sPositions = new Float32Array(dots * 3);

    for (let i = 0; i < dots; i++) {
      sIndices[i] = i;

      sPositions[i * 3 + 0] = i % width;
      sPositions[i * 3 + 1] = Math.floor(i / width);
    }

    return { sIndices, sPositions };
  }, [dots, width]);

  useLayoutEffect(() => {
    const tempObject = new Object3D();

    for (let index = 0; index < dots; index++) {
      tempObject.position.set(
        sPositions[index * 3 + 0],
        sPositions[index * 3 + 1],
        sPositions[index * 3 + 2]
      );
      tempObject.updateMatrix();
      instanceMeshRef.current.setMatrixAt(index, tempObject.matrix);
    }
    instanceMeshRef.current.instanceMatrix.needsUpdate = true;

    const animateParticles = () => {
      gsap
        .timeline()
        .to(uDepth, { duration: 2.0, value: 2.0, ease: "circ.out" })
        .to(uSize, { duration: 4.0, value: 1.0, ease: "power4.out" }, "-=1.0")
        .to(uDepth, { duration: 2.0, value: 60.0, ease: "circ.out" })
        .to(uSize, { duration: 4.0, value: 0, ease: "power4.out" }, "-=1.0")
        .add(animateParticles);
    };

    animateParticles();

    return () => {
      gsap.killTweensOf(uDepth);
      gsap.killTweensOf(uSize);
    };
  }, [dots, sPositions, uDepth, uSize]);

  useFrame(({ clock }) => {
    instanceMeshRef.current.material.uniforms.uTime.value = clock.elapsedTime;
  });

  const uniforms = {
    uSize,
    uDepth,
    uTime: { value: 0.0 },
    uRayTexture: { value: texture },
    uTexture: { value: text },
    uTextureSize: { value: new Vector2(width, height) },
  };

  return (
    <React.Fragment>
      <instancedMesh ref={instanceMeshRef} args={[null, null, dots]}>
        <planeBufferGeometry attach="geometry" args={[1, 1]}>
          <instancedBufferAttribute
            attachObject={["attributes", "offset"]}
            args={[sPositions, 3, false]}
          />
          <instancedBufferAttribute
            attachObject={["attributes", "index"]}
            args={[sIndices, 1, false]}
          />
        </planeBufferGeometry>
        <shaderMaterial
          attach="material"
          uniforms={uniforms}
          fragmentShader={fragment}
          vertexShader={vertex}
          transparent={true}
          depthTest={false}
        />
      </instancedMesh>
    </React.Fragment>
  );
};

function CameraController() {
  const { camera, set } = useThree();
  const isSmallScreen = window.innerWidth <= 900;

  useEffect(() => {
    camera.position.z = isSmallScreen ? 550 : 400;
    camera.updateProjectionMatrix();
    set({ camera });
  }, [camera, set, isSmallScreen]);

  return null;
}

export default function Background() {
  const cameraProps = {
    fov: 75,
    near: 1,
    far: 2000,
    position: [0, 0, 1050],
  };

  return (
    <div id="back-container">
      <Canvas
        gl={{ alpha: false }}
        dpr={[window.devicePixelRatio, 2]}
        camera={cameraProps}
        colorManagement={true}
      >
        <Suspense fallback={<CanvasLoader />}>
          <Particles />
        </Suspense>
        <CameraController />
        <OrbitControls
          enablePan={false}
          enableZoom={true}
          enableRotate={false}
        />
        <Preload all />
      </Canvas>
    </div>
  );
}
