import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

const Contactus = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [mobile, setMobile] = useState("");
  const [org, setOrg] = useState("");
  const [designation, setDesignation] = useState("");
  const [country, setCountry] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        "service_jcn1phi",
        "template_hsfi1cx",
        {
          from_name: name,
          to_name: "Manish Soni",
          from_email: email,
          to_email: "info@ocpl.tech",
          message: message,
          mobile: mobile,
          organisation: org,
          designation: designation,
          country: country,
        },
        "LFFcvS14r4Uqi0Too"
      )
      .then(
        () => {
          setLoading(false);
          alert("Thank you. I will get back to you as soon as possible.");

          setName("");
          setEmail("");
          setMessage("");
          setDesignation("");
          setMobile("");
          setOrg("");
          setCountry("");
          setType("");
        },
        (error) => {
          setLoading(false);
          console.error(error);

          alert("Ahh, something went wrong. Please try again.");
        }
      );
  };

  return (
    <>
      <Navbar />
      <div className="contact-us ">
        <div className="container mx-auto">
          <div className="title-wrapper">
            <h1
              data-text="Contact Us"
              className=" lg:text-4xl text-3xl text-center pt-8 text-white title"
            >
              Contact Us
            </h1>
          </div>
          <div className="lg:mx-48 md:mx-20 sm:mx-12 mx-10">
            <form
              ref={formRef}
              onSubmit={handleSubmit}
              className="mt-12 flex flex-col gap-8 shadow-[rgba(6,_24,_44,_0.4)_0px_0px_0px_2px,_rgba(6,_24,_44,_0.65)_0px_4px_6px_-1px,_rgba(255,_255,_255,_0.08)_0px_1px_0px_inset]"
            >
              <div className="sm:grid grid-cols-2 gap-10 flex flex-col">
                <label className="flex flex-col">
                  <span className="text-white font-medium mb-4">Your Name</span>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="What's your good name?"
                    className="bg-tertiary py-4 px-6 placeholder:text-secondary rounded-lg outline-none border-none font-medium"
                  />
                </label>
                <label className="flex flex-col">
                  <span className="text-white font-medium mb-4">
                    Your Email
                  </span>
                  <input
                    type="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="What's your web address?"
                    className="bg-tertiary py-4 px-6 placeholder:text-secondary  rounded-lg outline-none border-none font-medium"
                  />
                </label>
                <label className="flex flex-col">
                  <span className="text-white font-medium mb-4">
                    Your Mobile Number
                  </span>
                  <input
                    type="text"
                    name="mobile"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    placeholder="What's your Mobile Number?"
                    className="bg-tertiary py-4 px-6 placeholder:text-secondary  rounded-lg outline-none border-none font-medium"
                  />
                </label>
                <label className="flex flex-col">
                  <span className="text-white font-medium mb-4">
                    Your Organisation Name
                  </span>
                  <input
                    type="text"
                    name="org"
                    value={org}
                    onChange={(e) => setOrg(e.target.value)}
                    placeholder="What's your Organisation Name?"
                    className="bg-tertiary py-4 px-6 placeholder:text-secondary rounded-lg outline-none border-none font-medium"
                  />
                </label>
                <label className="flex flex-col">
                  <span className="text-white font-medium mb-4">
                    Your Designation
                  </span>
                  <input
                    type="text"
                    name="designation"
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                    placeholder="What's your designation?"
                    className="bg-tertiary py-4 px-6 placeholder:text-secondary  rounded-lg outline-none border-none font-medium"
                  />
                </label>
                <label className="flex flex-col">
                  <span className="text-white font-medium mb-4">
                    Your Country
                  </span>
                  <input
                    type="text"
                    name="country"
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    placeholder="What's your country?"
                    className="bg-tertiary py-4 px-6 placeholder:text-secondary rounded-lg outline-none border-none font-medium"
                  />
                </label>

                <label className="flex flex-col">
                  <span className="text-white font-medium mb-4">
                    Your Message
                  </span>
                  <textarea
                    rows={1}
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="What you want to say?"
                    className="bg-tertiary py-4 px-6 placeholder:text-secondary rounded-lg outline-none border-none font-medium"
                  />
                </label>
                <label className="flex flex-col">
                  <span className="text-white font-medium mb-4">
                    Type of Requirement
                  </span>
                  <select
                    name="select"
                    id="usage"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    className="bg-tertiary py-4 px-6 placeholder:text-secondary rounded-lg outline-none border-none font-medium"
                  >
                    <option disabled value="">
                      Select
                    </option>
                    <option value="personal">Personal</option>
                    <option value="business">Business</option>
                  </select>
                </label>
              </div>
              <button
                type="submit"
                className="org-btn font-semibold py-3 px-8 rounded w-60 transition duration-300"
              >
                {loading ? "Sending..." : "Send"}
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contactus;
