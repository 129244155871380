import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./Features.css";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import Lottie from "react-lottie";

function useTilt(animationDuration = "150ms") {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const unify = (e) => (e.changedTouches ? e.changedTouches[0] : e);

    const state = {
      rect: undefined,
      mouseX: undefined,
      mouseY: undefined,
    };

    let el = ref.current;

    const handleEnterEvent = () => {
      el.style.transition = `transform ${animationDuration} ease-out`;
    };

    const handleMoveEvent = (e) => {
      e.preventDefault();

      if (!el) {
        return;
      }
      if (!state.rect) {
        state.rect = el.getBoundingClientRect();
      }
      state.mouseX = unify(e).clientX;
      state.mouseY = unify(e).clientY;

      const px = (state.mouseX - state.rect.left) / state.rect.width;
      const py = (state.mouseY - state.rect.top) / state.rect.height;

      el.style.setProperty("--px", px.toFixed(2));
      el.style.setProperty("--py", py.toFixed(2));
    };

    const handleEndEvent = () => {
      el.style.setProperty("--px", 0.5);
      el.style.setProperty("--py", 0.5);
      el.style.transition = `transform ${animationDuration} ease-in`;
    };

    el.addEventListener("mouseenter", handleEnterEvent);
    el.addEventListener("mousemove", handleMoveEvent);
    el.addEventListener("mouseleave", handleEndEvent);
    el.addEventListener("touchstart", handleEnterEvent);
    el.addEventListener("touchmove", handleMoveEvent);
    el.addEventListener("touchend", handleEndEvent);

    return () => {
      el.removeEventListener("mouseenter", handleEnterEvent);
      el.removeEventListener("mousemove", handleMoveEvent);
      el.removeEventListener("mouseleave", handleEndEvent);
      el.removeEventListener("touchstart", handleEnterEvent);
      el.removeEventListener("touchmove", handleMoveEvent);
      el.removeEventListener("touchend", handleEndEvent);
    };
  }, [animationDuration]);

  return ref;
}

const Slide = ({ image, title, description, offset, lottie }) => {
  const active = offset === 0 ? true : null,
    ref = useTilt(active);

  return (
    <div
      ref={ref}
      className="slide"
      data-active={active}
      style={{
        "--offset": offset,
        "--dir": offset === 0 ? 0 : offset > 0 ? 1 : -1,
      }}
    >
      <div
        className="slideContent"
        style={{
          backgroundImage: `url('${image}')`,
        }}
      >
        <div className="slideContentInner">
          <div className="lottieContainer">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: lottie,
              }}
            />
          </div>
          {title && (
            <h3 className="slideTitle" dir="auto">
              {title}
            </h3>
          )}
          {description && (
            <p className="slideDescription" dir="auto">
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

Slide.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  offset: PropTypes.number.isRequired,
};

const Features = ({ slides }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const handlePrevSlide = () => {
    setSlideIndex((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleNextSlide = () => {
    setSlideIndex((prev) => (prev + 1) % slides.length);
  };

  return (
    <div className="features">
      <div className="container mx-auto" id="features">
        <h2 className="text-center text-2xl  md:text-5xl font-black mt-12 mb-10">
          Features
        </h2>
        <section className="slidesWrapper">
          <div className="slides">
            <button className="prevSlideBtn" onClick={handlePrevSlide}>
              <span className="iconWrapper">
                <GrLinkPrevious />
              </span>
            </button>

            {[...slides, ...slides, ...slides].map((slide, i) => {
              let offset = slides.length + (slideIndex - i);

              if (typeof slide === "string") {
                return <Slide image={slide} offset={offset} key={i} />;
              } else {
                return (
                  <Slide
                    image={slide.image}
                    title={slide.title}
                    subtitle={slide.subtitle}
                    description={slide.description}
                    offset={offset}
                    key={i}
                    lottie={slide.lottie}
                  />
                );
              }
            })}
            <button className="nextSlideBtn" onClick={handleNextSlide}>
              <span className="iconWrapper">
                <GrLinkNext />
              </span>
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Features;

Features.propTypes = {
  slides: PropTypes.array.isRequired,
  isPageBackground: PropTypes.bool,
};
