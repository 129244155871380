import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Hero = ({ heroContent }) => {
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
 
      <section className="hero-section">
        <div className="container mx-auto">
          <div className="title-wrapper">
            <h1
              data-text="Your Personal AI Assistant"
              className=" lg:text-5xl text-3xl text-center pt-8 text-white title"
            >
              Your Personal AI Assistant
            </h1>
          </div>
          <Slider
            {...settings}
            className="slider m-0 font-semibold text-center"
          >
            {heroContent.slider.map((slide) => {
              return (
                <p className="md:text-2xl text-lg" key={slide.id}>
                  {slide.line}
                </p>
              );
            })}
          </Slider>
          <a href="/signUp">
            <button className=" hero-btn font-semibold py-3 px-8 rounded w-60 transition duration-300 absolute top-3/4">
              {heroContent.buttonText}
            </button>
          </a>
        </div>
      </section>

  );
};

export default Hero;
