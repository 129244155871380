import "./App.css";
import React, { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Home/Home";
import SucessPage from "./Payment/success";
import FailurePage from "./Payment/failure";
import Contactus from "./ContactUs/contactus";
const SignUp = React.lazy(() => import("./SignUp/SignUp"));
const User = React.lazy(() => import("./UserPage"));
const Org = React.lazy(() => import("./OrgPage"));
const Refund = React.lazy(() => import("./RefundPolicy/Refund"));
const Terms = React.lazy(() => import("./Terms/Terms"));
const Privacy = React.lazy(() => import("./Privacy/Privacy"));
const Detail = React.lazy(() => import("./Details/Detail"));
const Page404 = React.lazy(() => import("./Page404/Page404"));
function App() {
  return (
    <BrowserRouter>
      <>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            path="/signUp"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <SignUp />
              </Suspense>
            }
          ></Route>
          <Route
            path="/user"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <User />
              </Suspense>
            }
          ></Route>
          <Route
            path="/organisation"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Org />
              </Suspense>
            }
          ></Route>
          <Route
            path="/refund"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Refund />
              </Suspense>
            }
          ></Route>
          <Route
            path="/terms"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Terms />
              </Suspense>
            }
          ></Route>
          <Route
            path="/privacy"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Privacy />
              </Suspense>
            }
          ></Route>
          <Route
            path="/documentation"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Detail />
              </Suspense>
            }
          ></Route>
          <Route
            path="/response/success"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <SucessPage />
              </Suspense>
            }
          ></Route>
          <Route
            path="/response/failure"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <FailurePage />
              </Suspense>
            }
          ></Route>
          <Route
            path="/contact"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Contactus />
              </Suspense>
            }
          ></Route>
          <Route
            path="/*"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <Page404 />
              </Suspense>
            }
          ></Route>
        </Routes>
      </>
    </BrowserRouter>
  );
}

export default App;
