import Swal from "sweetalert2";

const SucessPage = () => {

  Swal.fire({
    title: `Your Payment was Successful`,
    text: 'Thank you for Subscribing Aestra Premium',
    icon: 'success',
    footer: '<small>You will get your transaction details on your registered mobile number shortly!</small>',
    // timer: 5000,
    // timerProgressBar: true,

    // 'Your Payment was Success',
    // 'T,
    willClose: () => {
        window.location.href="https://aestra.ocpl.tech";
      },
     })


  return <div>Your Payment was Successful</div>;
};
export default SucessPage;
