import React, { useState, useEffect } from "react";
import Logo from "../Assets/Images/Whats.webp";
import { BsFillTelephoneFill } from "react-icons/bs";
import aestra from ".././Assets/Images/aestra.webp";
import ocpl from ".././Assets/Images/OCPL Tech.webp";
import { useNavigate } from "react-router-dom";
const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setIsOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const navigateHomeAndScrollTo = (section) => {
    setIsOpen(false);
    navigate(`/#${section}`);
  };
  return (
    <nav className="bg-transparent shadow-lg">
      <div className=" container mx-auto px-3">
        <div className="flex justify-between">
          <div className="flex space-x-7">
            <div>
              <a href="/" className="flex items-center ">
                <img
                  src={Logo}
                  alt="Logo"
                  className="max-h-16 md:w-40 mr-4 w-24"
                />
                <span className="font-semibold flex flex-col  ">
                  <img
                    src={aestra}
                    className="max-h-16 md:w-48 w-32"
                    alt=""
                  ></img>
                  <div className="flex items-start mt-1">
                    <span className="inline-block text-white">By</span>
                    <img src={ocpl} className="ml-1 mt-1 w-20" alt=""></img>
                  </div>
                </span>
              </a>
            </div>
          </div>

          <div className="hidden xl:flex items-center space-x-1">
            <a
              href="#works"
              onClick={() => navigateHomeAndScrollTo("works")}
              className="py-4 px-2  cursor-pointer  navlinks font-semibold transition duration-300"
            >
              Works
            </a>
            <a
              href="#services"
              onClick={() => navigateHomeAndScrollTo("services")}
              className="py-4 px-2  cursor-pointer  font-semibold navlinks transition duration-300"
            >
              Services
            </a>
            <a
              href="#features"
              onClick={() => navigateHomeAndScrollTo("features")}
              className="py-4 px-2  cursor-pointer  font-semibold navlinks transition duration-300"
            >
              Features
            </a>
            <a
              href="/documentation"
              className="py-4 px-2  cursor-pointer  font-semibold navlinks transition duration-300"
            >
              Documentation
            </a>
            <a
              href="/contact"
              className="block text-sm px-2 text-center navlinks py-4 font-semibold transition duration-300"
            >
              Contact Us
            </a>
            <a
              href="/signUp"
              className="py-4 px-2 number flex items-center  font-semibold navlinks transition duration-300"
            >
              <BsFillTelephoneFill />
              <span className="ml-2 ">+1 (620) 516-5861</span>
            </a>
          </div>

          <div className="xl:hidden flex items-center">
            <button
              className="outline-none mobile-menu-button"
              style={{ width: "max-content", margin: "0" }}
              onClick={toggleMenu}
            >
              <svg
                className=" w-6 h-6  navlinks transition duration-300"
                x-show="!showMenu"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className={`mobile-menu ${isOpen ? "" : "hidden"}`}>
        <ul className="absolute z-50  w-full">
          <li>
            <a
              href="#works"
              className="block text-sm px-2 text-center navlinks  py-4 text font-semibold transition duration-300"
              onClick={() => navigateHomeAndScrollTo("works")}
            >
              Works
            </a>
          </li>
          <li>
            <a
              href="#services"
              className="block text-sm px-2 text-center navlinks py-4 font-semibold transition duration-300"
              onClick={() => navigateHomeAndScrollTo("services")}
            >
              Services
            </a>
          </li>

          <li>
            <a
              href="#features"
              className="block text-sm px-2 text-center navlinks py-4 font-semibold transition duration-300"
              onClick={() => navigateHomeAndScrollTo("features")}
            >
              Features
            </a>
          </li>
          <li>
            <a
              href="/documentation"
              className="block text-sm px-2 text-center navlinks py-4 font-semibold transition duration-300"
            >
              Documentation
            </a>
          </li>
          <li>
            <a
              href="/contact"
              className="block text-sm px-2 text-center navlinks py-4 font-semibold transition duration-300"
            >
              Contact Us
            </a>
          </li>
          <li>
            <a
              href="/signUp"
              className="flex number justify-center items-center navlinks text-sm px-2 text-center  py-4   font-semibold transition duration-300"
            >
              <BsFillTelephoneFill />
              <span className="ml-2">+1 (620) 516-5861</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
